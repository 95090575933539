import { Routes, Route } from "react-router-dom";
import MentionsLegales from "../Page/MentionsLegales/MentionsLegales.js";
import PolitiqueConfidentialite from "../Page/PolitiqueConfidentialite/PolitiqueConfidentialite";
import HomePage from "../Page/HomePage/HomePage";


export default function Routing() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
        </Routes>
    )
} 