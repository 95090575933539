import React, { useEffect } from "react";
import ArticlePolitiqueConfidentialite from "../../components/ArticlePolitiqueConfidentialite/ArticlePolitiqueConfidentialite";
import TitlePage from "../../components/TitlePage/TitlePage";
import Navbar from "../../components/NavBar/Navbar";
import Footer from "../../components/Footer/Footer";


function PolitiqueConfidentialite() {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main className="d-flex flex-column align-items-center mb-3">
      <Navbar/>
      <TitlePage title="POLITIQUE DE CONFIDENTIALITÉ" />
      <ArticlePolitiqueConfidentialite />
      <Footer/>
    </main>
  );
}

export default PolitiqueConfidentialite;
