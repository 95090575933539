import React from "react";
import ButtonBar from "../ButtonBar/Button-bar";
import panneau from "../../Images/panneau.png";
import main from "../../Images/main.png";
import eolienne from "../../Images/energie_renouvelable_1.png";
import ecran from "../../Images/ecran.png";
import ContainerDesc from "../ContainerDesc/Container_desc";
import Titre from "../Titre/Titre";

function Home() {
  const background = "fondV";
  return (
    <div className="hoverflow-hidden">
      <div>
        <Titre/>
        <ButtonBar />
        <ContainerDesc
          inversion="1"
          titre="Découvrez nos offres Électricité"
          description="Que vous recherchiez un fournisseur d'énergie pour votre résidence actuelle ou pour votre future habitation suite à un déménagement, ChezSwitch est à vos côtés. En tant que fournisseur électrique, nous mettons à disposition des offres claires et adaptées à vos exigences pour vous accompagner au quotidien."
          image={panneau}
          altImg="Illustration de panneaux solaire"
          />
        <ContainerDesc
          inversion="0"
          backgroundTitre={background}
          titre="Switch Energy, l'électricité verte simplifiée."
          sousTitre="Simple, transparent et sans surprise."
          description="Nous sommes conscients que l'énergie peut être un domaine complexe et générer de la confusion chez nos clients. C'est pourquoi, avec ChezSwitch, notre engagement se concentre sur la simplification. Notre objectif est de vous offrir une expérience claire et compréhensible, en vous guidant à travers les tenants et aboutissants, sans vous exposer aux tracas des frais cachés ou des surprises déplaisantes. Notre équipe est présente pour vous soutenir, prête à répondre à toutes vos interrogations de manière franche et transparente, afin de vous assurer une tranquillité d'esprit totale. "
          image={main}
          altImg="Illustration d'une main tenant des personnes"
          />
        <ContainerDesc
          inversion="1"
          background={background}
          sousTitre="Des offres vertes"
          description="En choisissant l'option d'énergie verte avec ChezSwitch, vous faites le choix de contribuer à un engagement écologique qui met l'accent sur la durabilité, la transition énergétique et la production renouvelable en Europe. Les certificats qui témoignent de l'origine de votre énergie seront aisément accessibles depuis votre espace personnel."
          image={eolienne}
          altImg="Illustration d'eoliennes et de panneaux solaire"
          />
        <ContainerDesc
          inversion="0"
          sousTitre="Souscription rapide et facile"
          description="Nous avons élaboré une procédure de souscription en ligne rapide et aisée pour nos clients. Nous comprenons la valeur de votre temps, c'est pourquoi nous avons simplifié chaque phase du processus d'adhésion. Vous pouvez finaliser votre souscription en quelques minutes seulement, sans nécessité de compléter des formulaires complexes ou de soumettre des documents laborieux. Notre équipe est présente pour vous accompagner à chaque étape et résoudre toutes vos interrogations. Avec ChezSwitch, adhérer à notre service électrique est synonyme de simplicité et de rapidité."
          image={ecran}
          altImg="Illustration d'écran"
        />
      </div>
      <div className="mb-4 mx-auto">
        <div className="mx-auto d-flex justify-content-center">
          <a href="tel:+0189471956">
            <button className="button border border-3 rounded-3 mx-auto">Souscrire au 01 89 47 19 56</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
