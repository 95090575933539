import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import logo from "../../Images/logo.png";
import logoSwitch from "../../Images/logoSwitch.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <header className="Navbar bg-white position-fixed top-0 w-100">
      <nav>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Link to="/">
              <img src={logo} className="logo" alt="logo ouverture de contrat"/>
            </Link>
          </div>
          <div className="d-flex">
            <a href="tel:+0189471956">
              <button className="button d-inline-block fw-bold rounded-3 border border-2 bg-light">Souscrire au 01 89 47 19 56</button>
            </a>
            <div>
              <a href="https://www.chezswitch.fr/#/" target="_blank" rel="noreferrer">
                <img src={logoSwitch} className="logo-switch" alt='Logo "chez switch"'/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
