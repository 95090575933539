import React from "react";
import "./img_desc.css";

function Img_desc(props) {
  const inversionDesc = props.inversion === "1";
  return (
    <div
      className={`image-div p-0 col-12 col-md-6 d-flex justify-content-center  ${
        inversionDesc ? "order-md-first" : ""
      }`}
    >
      <img
        src={props.image}
        alt={props.altImg}
        className="responsive-image p-0 image-fluid h-auto"
      />
    </div>
  );
}

export default Img_desc;
