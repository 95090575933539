import React from "react";
import ImgDesc from "../ImgDesc/Img_desc";
import TextDesc from "../TextDesc/Text_desc";
import "./container_desc.css";

function Container_desc(props) {
  return (
    <div className={`row w-100 p-4 mx-auto ${props.background}`}>
      <TextDesc
        titre={props.titre}
        backgroundTitre={props.backgroundTitre}
        sousTitre={props.sousTitre}
        description={props.description}
      />
      <ImgDesc image={props.image} inversion={props.inversion} altImg={props.altImg}/>
    </div>
  );
}

export default Container_desc;
