import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Titre.css";
import imgTitre from "../../Images/img-Titre.png";

function Titre() {
  return (
    <article id="banner" className="container-fluid d-flex align-items-center justify-content-center p-0">
      <h1 className="titre m-0 text-center fw-bold">
        Ouvrez votre contrat d'électricité aujourd'hui !
      </h1>
      <img src={imgTitre} className="imgTitre" alt="Illustration pour contrat d'électricité"/>
    </article>
  );
};

export default Titre;
