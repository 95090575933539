import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Button-bar.css";
import iconeBulb from "../../Images/iconeBulb.svg";
import iconePhone from "../../Images/iconePhone.svg";

function Button_bar  ()  {
  return (
    <article className="button-bar col-8">
      <a href="https://www.chezswitch.fr/offre-energie/#/" target="_blank" rel="noreferrer">
        <button className="button-souscrire">
          <img src={iconeBulb} className="iconeBulb" alt="Icone d'ampoule"/>
          Souscrire en ligne
        </button>
      </a>

      <a href="https://www.chezswitch.fr/forfait-mobile/#/">
        <button className="button-souscrire">
          <img src={iconePhone} className="iconePhone" alt="Icône de téléphone"/>
          Souscrire au téléphone
        </button>
      </a>

      <a href="tel:+0189471956">
        <button className="button-contacte">
          Contactez-nous au 01 89 47 19 56
        </button>
      </a>
    </article>
  );
};

export default Button_bar;
