import React, { useEffect } from "react";

import Home from "../../components/Home/Home";
import Navbar from "../../components/NavBar/Navbar";
import Footer from "../../components/Footer/Footer";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main className="d-flex flex-column align-items-center mb-3">
      <Navbar />
      <Home />
      <Footer />
    </main>
  );
}

export default HomePage;
