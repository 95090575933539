import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Routing from "./Routes/Routes";

function App() {
  return <Routing />;
}

export default App;
